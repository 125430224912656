import './App.css';
import React from 'react';

class App extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
    }
  }

  componentDidMount() {
    fetch("https://posts.sheikhmusalin.workers.dev"
  //   , {
  //     method: "POST",
  //     body: JSON.stringify(),
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json',
  //   }
  // }
  )
      .then(res => res.json())
      .then(json => {
        this.setState({
         isLoaded: true,
         items: json,
        })
        
      });
      // fetch("http://127.0.0.1:8787")
      // https://posts.sheikhmusalin.workers.dev
  }

  
  
  render() {

    var { isLoaded , items} = this.state;

    if (!isLoaded) {
      return <div>Loading...</div>
    } 
    
    else {

      return (
        <div className="App">
          <h1>Data has been loaded</h1>
          
        </div>
      );

    }

  } 
}
export default App;
